export const JobCardSkeleton: React.FC = () => (
    <div className="py-2">
      <div className="flex flex-col p-5 border rounded-md animate-pulse border-staff-320 dark:bg-white ">
        <div className="w-1/6 h-2 rounded bg-slate-200 dark:bg-slate-700"></div>
        <div className="flex justify-start w-1/4 my-4 space-x-3">
          <div className="w-1/3 h-2 rounded bg-slate-200 dark:bg-slate-700"></div>
          <div className="w-1/3 h-2 rounded bg-slate-200 dark:bg-slate-700"></div>
        </div>
        <div className="rounded h-14 bg-slate-200 dark:bg-slate-700"></div>
        <div className="flex items-center justify-between mt-4">
          <div className="w-1/12 h-2 rounded bg-slate-200 dark:bg-slate-700"></div>
          <div className="w-1/5 h-10 bg-slate-200 dark:bg-slate-700 rounded-3xl"></div>
        </div>
      </div>
    </div>
  );

  export const NewJobCardSkeleton: React.FC = () => (
    <div
      className="
          min-w-[288px] min-h-[202px]  md:min-w-full flex flex-col justify-between h-full border rounded-md border-gray-300 bg-gray-100 p-5 animate-pulse"
    >
      <div>
        <div className="flex justify-between cursor-pointer mb-3">
          <div className="h-5 w-3/4 bg-gray-300 rounded-md"></div>
        </div>
  
        <div className="flex justify-start my-4 space-x-3">
          <div className="flex space-x-2 place-content-start">
            <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
            <div className="h-4 w-1/3 bg-gray-300 rounded-md"></div>
          </div>
          <div className="flex space-x-2 place-content-start">
            <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
            <div className="h-4 w-1/3 bg-gray-300 rounded-md"></div>
          </div>
        </div>
  
        <div className="mb-3 space-y-3">
          <div className="h-4 w-2/3 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-2/3 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-1/2 bg-gray-300 rounded-md"></div>
          <div className="h-4 w-1/4 bg-gray-300 rounded-md"></div>
        </div>
  
        <div className="flex gap-5 justify-end mb-3">
          <div className="h-8 w-32 bg-gray-300 rounded-3xl"></div>
          <div className="h-8 w-24 bg-gray-300 rounded-3xl"></div>
        </div>
  
        <div className="max-h-[500px] overflow-hidden space-y-4">
          <div className="h-5 w-1/3 bg-gray-300 rounded-md"></div>
          <div className="h-16 w-full bg-gray-300 rounded-md"></div>
          <div className="h-5 w-1/3 bg-gray-300 rounded-md"></div>
          <div className="h-16 w-full bg-gray-300 rounded-md"></div>
          <div className="h-5 w-1/3 bg-gray-300 rounded-md"></div>
          <div className="h-16 w-full bg-gray-300 rounded-md"></div>
        </div>
  
        <div className="max-h-[500px] overflow-hidden space-y-4">
          <div className="h-5 w-1/3 bg-gray-300 rounded-md"></div>
          <div className="h-16 w-full bg-gray-300 rounded-md"></div>
          <div className="h-5 w-1/3 bg-gray-300 rounded-md"></div>
          <div className="h-16 w-full bg-gray-300 rounded-md"></div>
          <div className="h-5 w-1/3 bg-gray-300 rounded-md"></div>
          <div className="h-16 w-full bg-gray-300 rounded-md"></div>
        </div>
      </div>
  
      <div className="flex-col justify-between mt-4">
        <div className="h-4 w-1/3 bg-gray-300 rounded-md"></div>
      </div>
    </div>
  );